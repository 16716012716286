<template>
  <div @touchmove.prevent class="wrapper">
    <scroll class="scroll" ref="scroll" :data="data">
      <div>
        <div class="container">
          <div class="header">
            <div class="back" @click="back">
              <div class="icon"></div>
              <div class="name">返回</div>
            </div>
          </div>
          <iframe
            class="content"
            :src="decodeURIComponent($route.params.url) + `?c_agency_guid=${agencyGuid}&openid=${openid}`"
          ></iframe>
        </div>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { mapGetters } from 'vuex'

export default {
  name: 'Location',
  computed: {
    ...mapGetters([
      'agencyGuid',
      'openid'
    ])
  },
  components: {
    Scroll
  },
  data () {
    return {
      data: []
    }
  },
  activated () {
    setTimeout(() => {
      this.$refs.scroll.refresh()
    }, 100)
  },
  methods: {
    back () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  position absolute
  z-index 10
  top 0
  bottom 0
  left 0
  right 0
  background #fff

  .scroll
    position absolute
    z-index 10
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden

    .container
      display flex
      flex-direction column
      width 100%
      min-height 100vh

      .header
        width 100%
        height 84px

        .back
          display flex
          align-items center
          width 159px
          height 84px

          .icon
            width 16px
            height 28px
            margin-left 43px
            bg-image('~@/assets/img/reading/back')
            background-size 100% 100%
            background-repeat no-repeat

          .name
            width 100px
            line-height normal
            font-size 26px
            font-weight 500
            color rgba(51, 51, 51, 1)
            margin-left 10px

      .content
        flex 1
        width 750px

</style>
